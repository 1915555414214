import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Storage from '@/common/storage';
import { StorageKey, StorageScopeKey } from '@/config/constant';
import pageConfigs from '@/config/pages';

const authStorage = new Storage(StorageScopeKey.AUTH, process.env.VERSION);

interface AxiosRequestConfig2 extends AxiosRequestConfig {
  isReturnRes: boolean
}

interface AxiosResponse2 extends AxiosResponse {
  config: AxiosRequestConfig2
}

interface AjaxResponse {
  code: number,
  data: any,
  message: string
}

export const defaultRequestConfig = {
  cache: false,
  autoShowErr: true,
  baseUrl: '/api/v1',
};

export function requestOkInterceptor(config: any) {
  const mergeConfig = {
    ...defaultRequestConfig,
    ...config,
  };

  // 要判断白名单，非白名单才获取 access-token
  if (!config.noAuth) {
    mergeConfig.headers['access-token'] = authStorage.getItem(StorageKey.ACCESS_TOKEN)?.data;
  }

  return mergeConfig;
}

export function responseSuccessInterceptor(response: AxiosResponse2) {
  const { config, data = {} } = response;
  return config.isReturnRes ? response : data;
}

function validateCode(response: AjaxResponse): AjaxResponse | void {
  if ([401, 400].includes(response.code)) {
    setTimeout(() => {
      window.location.href = pageConfigs.Login.path;
    }, 1000);
  }

  return response;
}

export function responseErrorInterceptor(err: any) {
  const { response } = err;
  let errCode = null;
  let errMessage = '';
  if (response) {
    const { status } = response;
    errCode = status;
    switch (status) {
      case 404:
        errMessage = '请求发生404错误';
        break;
      case 500:
        errMessage = '请求发生500错误';
        break;
      case 504:
        errMessage = '请求超时';
        break;
      case 400: // 用户没有csrf-token
      case 401: // 用户没有登录态
        // 用户未登录
        errMessage = '用户没有登录态';
        break;
      default:
        errMessage = `error:${err.response.status}`;
        break;
    }
  }
  return Promise.resolve(validateCode({ code: errCode, message: errMessage, data: {} }));
}

export default {};
