/**
 * TODO：加入内存缓存支持
 * 加入版本概念的本地存储
 */
export default class Storage {
  private readonly scopeKey: string

  private readonly version: string

  constructor(scopeKey: string, version: string) {
    this.scopeKey = scopeKey;
    this.version = version;

    // 初始化，移除历史数据
    this._removeBeforeData(version);
  }

  /**
   * 设置缓存到本地
   * 1、取出某作用域下的缓存数组
   * 2、往该数组，插入数据
   * @param key 缓存key
   * @param data 缓存数据
   */
  setItem(key: string, data: any): void {
    try {
      const localData = this._getLocalData(this.version);
      localStorage.setItem(this.scopeKey, JSON.stringify(this._setItem(localData, key, data)));
    } catch (e) {
      console.error('设置缓存失败了', this.scopeKey, key, data);
      throw new Error(e);
    }
  }

  getItem(key: string, version = this.version): any {
    return this._getLocalData(version).find(({ key: key2 }) => key2 === key);
  }

  getAll(): any[] {
    return this._getLocalData(this.version);
  }

  removeItem(key: string): void {
    const newLocalData = this._getLocalData(this.version, key);
    localStorage.setItem(this.scopeKey, JSON.stringify(newLocalData));
  }

  /**
   * 移除掉该作用域下的左右数据。
   */
  clear(): void {
    localStorage.removeItem(this.scopeKey);
  }

  private _getLocalData(getVersion: string, excludeKey?: string): any[] {
    try {
      const localData: any[] = JSON.parse(localStorage.getItem(this.scopeKey) as string);
      // eslint-disable-next-line no-nested-ternary
      return Array.isArray(localData)
        ? excludeKey
          ? localData.filter(({ version, key }) => version === getVersion && key !== excludeKey)
          : localData.filter(({ version }) => version === getVersion)
        : [];
    } catch (e) {
      return [];
    }
  }

  /**
   * 往数据插入同样key数据
   * @param arr
   * @param key
   * @param version
   * @param data
   * @private
   */
  private _setItem(arr: any[], key: string, data: any): any[] {
    const newArr = arr.filter((item) => item.key !== key);
    newArr.push({ version: this.version, key, data });
    return newArr;
  }

  private _removeBeforeData(version: string): void{
    localStorage.setItem(this.scopeKey, JSON.stringify(this._getLocalData(version)));
  }
}
