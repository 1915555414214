import { PageTypes } from '@/config/constant';

export default {
  id: PageTypes.Home,
  path: '/home',
  code: 'home',
  name: '首页',
  isNav: false,
  sort: 99,
};
