
import { defineComponent, onMounted } from 'vue';
import { mapState } from 'vuex';
import { pageList } from '@/config/pages';
import auth from '@/services/auth';
import store from '@/store';

export default defineComponent({
  setup() {
    onMounted(() => {
      store.dispatch('getUserInfo');
      store.dispatch('getStoreInfo');
    });

    return {
    };
  },
  data() {
    return {
      navList: [],
    };
  },
  computed: {
    ...mapState(['menuList', 'storeInfo', 'userInfo']),
    currentRoutePath() {
      return this.$router.currentRoute.value.path;
    },
  },
  methods: {
    handleGoPath(nav: any) {
      this.$router.push(nav.path);
    },
    handleExit() {
      auth.exit(this.$router);
    },
  },
  watch: {
    menuList: {
      handler(val) {
        if (val.length) {
          const permissionList = auth.permissions.map((menu: any) => menu.code);
          this.navList = auth.permissions.length && pageList
            .filter((nav) => permissionList.includes(nav.code) && nav.isNav)
            .sort((a, b) => a.id - b.id);
        }
      },
    },
    'userInfo.status': {
      handler(val) {
        if (val === 2) this.handleExit();
      },
    },
  },
});
