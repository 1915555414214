import { PageTypes } from '@/config/constant';
// 批量加载所有的页面配置，转出对象结构
const pageRequires = require.context('@/modules', true, /page\.config\.ts$/);

export const pageList = pageRequires
  .keys()
  .map((filePath) => pageRequires(filePath).default);

const pageConfigs = pageList.reduce((pre, cur) => ({
  ...pre,
  [cur.id]: cur,
  [PageTypes[cur.id]]: cur,
}), {});

export default pageConfigs;
