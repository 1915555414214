const globalRequestConfig = {
  auth: {
    getLoginInfo: ['get', '/auth/login'],
    // 获取用户权限
    getUserInfo: ['get', '/employees/me'],
  },
  common: {
    // 获取门店信息
    getStoreInfo: ['get', '/dealer_stores/current'],
  },
};

export default globalRequestConfig;
