<template>
  <div class="search-panel">
    <slot></slot>
  </div>
</template>

<style lang="less" scoped>
.search-panel {
  background: #f6f7fb;
  margin: 14px 0;
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
}
</style>
