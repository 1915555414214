import {
  Router, RouteLocationNormalized, NavigationGuardNext,
} from 'vue-router';
import { stringify } from 'qs';
import ajax from '@/ajax';
import storage from '@/utils/storage';
import { StorageKey } from '@/config/constant';
import pageConfigs from '@/config/pages';
import store from '@/store';

class Auth {
  public authStorage

  public loginUrl: string | undefined

  constructor() {
    this.authStorage = storage;
  }

  get isLogin() {
    return !!this.authStorage.getItem(StorageKey.ACCESS_TOKEN);
  }

  get accessToken() {
    return this.authStorage.getItem(StorageKey.ACCESS_TOKEN);
  }

  get permissions() {
    return this.authStorage.getItem(StorageKey.PERMISSIONS)?.data || [];
  }

  async fetchLoginInfo() {
    const {
      code,
      data: {
        login_url: loginUrl = '',
      } = {},
    } = await ajax.$rest.login.getLoginInfo({
      next: `${window.location.origin}/testDrive`,
    });
    this.loginUrl = code === 0 ? loginUrl : '';
  }

  hasPermission(to: RouteLocationNormalized) {
    return this.permissions.some((permission: any) => to.meta.code === permission.code);
  }

  /**
   * 拦截路由请求
   * 在微信扫码后，后台跳回 url 参数中回带上 access-token。
   * 当 access-token 存在的时候，存储到本地、然后，去掉 access-token 参数后，重定向到本身。
   * @param to
   * @param next
   */
  async authGuards(to: RouteLocationNormalized, next: NavigationGuardNext): Promise<any> {
    const {
      query: {
        access_token: accessToken,
        ...restQuery
      },
      ...restTo
    } = to;

    if (accessToken) {
      this.setAccessTokenToLocal(accessToken as string);
      await store.dispatch('getUserInfo');
      const newUrl = stringify(restQuery) ? `${restTo.path}?${stringify(restQuery)}` : '';
      return next({
        ...restTo,
        href: newUrl,
        fullPath: newUrl,
        query: restQuery,
      } as RouteLocationNormalized);
    }

    // 检查权限
    return this.checkSession(to, next);
  }

  checkSession(to: RouteLocationNormalized, next: NavigationGuardNext) {
    // 白名单，不需要检查登录，比如 登录页，正常访问
    // 已经登录，有权限或首页，正常访问
    if (to.meta.doNotCheckAuth || (this.isLogin && (this.hasPermission(to) || to.name === 'Home'))) {
      return next();
    }

    // 已经登录，无权限，暂跳首页
    if (this.isLogin && !this.hasPermission(to)) {
      return next({ name: 'Home' });
    }

    // 其他情况，跳转登录页
    return next({ path: pageConfigs.Login.path });
  }

  setAccessTokenToLocal(accessToken: string) {
    this.authStorage.setItem(StorageKey.ACCESS_TOKEN, accessToken);
  }

  login() {
    if (typeof this.loginUrl === 'string' && this.loginUrl) {
      window.location.href = this.loginUrl;
    } else {
      console.log('登录url为空', this.loginUrl);
    }
  }

  exit(router: Router) {
    this.authStorage.removeItem(StorageKey.ACCESS_TOKEN);
    this.authStorage.removeItem(StorageKey.PERMISSIONS);
    return router.replace(pageConfigs.Login.path);
  }
}

export default new Auth();
