
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      hello: 'this is login index.vue',
    };
  },
});
