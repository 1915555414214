import { RouteRecordRaw } from 'vue-router';
import pageConfigs from '@/config/pages';
import { PageTypes } from '@/config/constant';

// layouts 组件
import { AppMain } from '../layouts';

// TODO：做成动态路由
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: AppMain,
    redirect: '/home',
    children: [
      { // 首页
        path: '/home',
        name: 'Home',
        meta: {
          title: pageConfigs[PageTypes.Home].name,
          code: 'home',
        },
        component: () => import(/* webpackChunkName: "home" */ '../modules/home/index.vue'),
      },
      { // 预约试驾页面
        path: '/testDrive',
        name: 'TestDrive',
        meta: {
          title: pageConfigs[PageTypes.TestDrive].name,
          code: 'test_drive',
        },
        component: () => import(/* webpackChunkName: "testDrive" */ '../modules/testDrive/index.vue'),
      },
      { // 订单管理页面
        path: '/order',
        name: 'Order',
        meta: {
          title: pageConfigs[PageTypes.Order].name,
          code: 'reservation',
        },
        component: () => import(/* webpackChunkName: "order" */ '../modules/order/index.vue'),
      },
      { // 运营推广
        path: '/operationPromotion',
        name: 'OperationPromotion',
        meta: {
          title: pageConfigs[PageTypes.OperationPromotion].name,
          code: 'promote',
        },
        component: () => import(/* webpackChunkName: "operationPromotion" */ '../modules/operationPromotion/index.vue'),
      },
    ],
  },
  {
    path: pageConfigs[PageTypes.Login].path,
    name: 'Login',
    meta: {
      doNotCheckAuth: true,
      title: pageConfigs[PageTypes.Login].name,
    },
    component: () => import(/* webpackChunkName: "login" */ '../modules/login/index.vue'),
  },
];

export default routes;
