import { PageTypes } from '@/config/constant';

export enum QrcodeWidthType {
  SMALL = 302,
  NORMAL = 453,
  MEDIUM = 566,
  REGULAR = 1133,
  LARGE = 1280
}

export const qrcodeWidthObj = {
  [QrcodeWidthType.SMALL]: '8',
  [QrcodeWidthType.NORMAL]: '12',
  [QrcodeWidthType.MEDIUM]: '15',
  [QrcodeWidthType.REGULAR]: '30',
  [QrcodeWidthType.LARGE]: '50',
};

export const qrcodeDistanceObj = {
  [QrcodeWidthType.SMALL]: '0.5',
  [QrcodeWidthType.NORMAL]: '0.8',
  [QrcodeWidthType.MEDIUM]: '1',
  [QrcodeWidthType.REGULAR]: '1.5',
  [QrcodeWidthType.LARGE]: '2.5',
};

export default {
  id: PageTypes.OperationPromotion,
  path: '/operationPromotion',
  code: 'promote',
  name: '运营推广',
  isNav: true,
  sort: 3,
};
