import { PageTypes } from '@/config/constant';

export default {
  id: PageTypes.TestDrive,
  path: '/testDrive',
  code: 'test_drive',
  name: '预约试驾',
  isNav: true,
  sort: 1,
};
