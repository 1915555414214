import { PageTypes } from '@/config/constant';

export enum OrderStatus {
  UN_KNOWN = 0,
  UN_PAID = 1,
  PAID = 2,
  DEAL = 3,
  CLOSED = 4,
}

export const OrderStatusDict = {
  [OrderStatus.UN_KNOWN]: '未知',
  [OrderStatus.UN_PAID]: '未支付',
  [OrderStatus.PAID]: '已支付',
  [OrderStatus.DEAL]: '已成交',
  [OrderStatus.CLOSED]: '已退订',
};

export enum SourceType {
  FROM_STORE = 1,
  FROM_PLATFORM,
  FROM_NATURE
}

export const SourceTypeDict = {
  [SourceType.FROM_STORE]: '门店推广',
  [SourceType.FROM_PLATFORM]: '平台推广',
  [SourceType.FROM_NATURE]: '自然客流',
};

export default {
  id: PageTypes.Order,
  path: '/order',
  code: 'reservation',
  name: '订单管理',
  isNav: true,
  sort: 2,
};
