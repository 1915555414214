import Ajax from '@lx-frontend/ajax';
import { responseSuccessInterceptor, requestOkInterceptor, responseErrorInterceptor } from './interceptor';

import globalRequestConfig from './config';

// 所有按钮
const ajaxConfigRequires = require.context('@/modules', true, /ajax\.config\.ts$/);
const ajaxConfigs = ajaxConfigRequires
  .keys()
  .map((filePath) => ajaxConfigRequires(filePath).default);

const ajaxConfigDict = ajaxConfigs.reduce((pre, cur) => ({ ...pre, ...cur }), {});

// rest配置
// 动态配置，各个页面的配置 ajax.config.js
const restConfig = {
  ...globalRequestConfig,
  ...ajaxConfigDict,
};

// 实例化配置
const options = {
  rest: restConfig,
  baseUrl: '/api/v1',
  engineType: 'axios',
};

// 实例化
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ajax = new Ajax(options);

// 注册拦截器
ajax.interceptors.request.use(requestOkInterceptor); // 请求拦截器
ajax.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor); // 响应拦截器

export const rest = ajax.$rest;
export interface ResponseData<T> {
  code: number,
  data: T,
  message: string
}
export default ajax;
