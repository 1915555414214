import { createRouter, createWebHistory } from 'vue-router';
import { navigationGuard } from './guards';

// 页面配置
import routes from '@/router/config';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 路由守护
export default navigationGuard(router);
