import { createStore } from 'vuex';
import ajax from '@/ajax';
import storage from '@/utils/storage';
import { StorageKey } from '@/config/constant';

export default createStore({
  state: {
    menuList: [],
    userInfo: {},
    storeInfo: [],
  },
  getters: {
    getMenuList(state) {
      return state.menuList.map((item: any) => item.code);
    },
  },
  mutations: {
    UPDATE_USER_INFO(state, data) {
      state.menuList = data.permissions;
      state.userInfo = data.employee;
      storage.setItem(StorageKey.PERMISSIONS, data.permissions);
    },
    UPDATE_STORE_INFO(state, data) {
      state.storeInfo = data;
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      const { code, data: { employee, permissions } } = await ajax.$rest.auth.getUserInfo();
      if (code === 0) {
        commit('UPDATE_USER_INFO', {
          employee,
          permissions: [
            ...permissions,
            {
              code: 'home',
              display_name: '首页',
            },
          ],
        });
      }
    },
    async getStoreInfo({ commit }) {
      const { code, data } = await ajax.$rest.common.getStoreInfo();
      if (code === 0) commit('UPDATE_STORE_INFO', data);
    },
  },
  modules: {
  },
});
