import { ButtonTypes } from '@/config/constant';

export const buttons = [
  {
    id: ButtonTypes.LoginBtn,
    name: '扫码登录',
  },
  {
    id: ButtonTypes.LoginBtn2,
    name: '按钮3',
  },
];

const buttonMap = buttons.reduce((pre, cur) => ({
  ...pre,
  [cur.id]: cur,
  [ButtonTypes[cur.id]]: cur,
}), {});

export default buttonMap;
