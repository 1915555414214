
import { defineComponent } from 'vue';

// 布局组件
import TopHead from './components/TopHead.vue';

export default defineComponent({
  components: {
    TopHead,
  },
});
