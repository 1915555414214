<template>
  <div class="panel-container">
    <slot></slot>
  </div>
</template>

<style lang="less" scoped>
.panel-container {
  background: #fff;
  margin: 14px 20px;
  border-radius: 4px;
  padding: 20px;
}
</style>
