import { Router } from 'vue-router';
import auth from '@/services/auth';

/**
 * 路由守护
 * @param router
 */
export function navigationGuard(router: Router): Router {
  router.beforeEach((to, from, next) => {
    auth.authGuards(to, next);
    if (to.meta.title) { // 如果设置标题，拦截后设置标题
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.title = to.meta.title;
    }
  });
  return router;
}

export default {};
