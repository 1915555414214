import { ButtonTypes } from '@/config/constant';
// 所有按钮
const configRequires = require.context('@/modules', true, /button\.config\.ts$/);

// eslint-disable-next-line
const buttonConfigs: any = {};

configRequires
  .keys()
  .forEach((filePath) => {
    const { buttons } = configRequires(filePath);
    if (Array.isArray(buttons)) {
      buttons.forEach((btn) => {
        buttonConfigs[ButtonTypes[btn.id]] = btn;
        buttonConfigs[btn.id] = btn;
      });
    }
  });

export default buttonConfigs;
