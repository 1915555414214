// 初始化配置
import './bootstrap';
import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';

import PanelContainer from '@/components/PanelContainer.vue';
import SearchPanel from '@/components/SearchPanel.vue';

import App from './App.vue';
import router from './router';
import models from './store';

// 全部按钮配置
import buttonConfigs from '@/config/buttons';
import pageConfigs from '@/config/pages';

// 全局ajax
import ajax from '@/ajax';

// 样式
import './assets/styles/app.less';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    btns: any
    pageConfig: any
    $http: any
  }
}

const app = createApp(App);
app.config.globalProperties.btns = buttonConfigs;
app.config.globalProperties.pageConfig = pageConfigs;
app.config.globalProperties.$http = ajax;

app.component('lx-container', PanelContainer);
app.component('lx-search-panel', SearchPanel);
app.use(models).use(router).use(ElementPlus, { locale: zhCn }).mount('#app');
