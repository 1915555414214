export enum PageTypes {
  Login = 1,
  Home,
  TestDrive,
  Order,
  OperationPromotion,
  NotFound
}

// 按钮编码
export enum ButtonTypes {
  LoginBtn = 1,
  LoginBtn2,
}

export enum StorageKey {
  ACCESS_TOKEN = 'access_token',
  PERMISSIONS = 'permissions'
}

export enum StorageScopeKey {
  AUTH = 'authStorage'
}

export default {};
